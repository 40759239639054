@font-face {
    font-family: "CenturyGothicBold";
    src: local("CenturyGothicBold"),
        url(./fonts/CenturyGothicBold.ttf) format("truetype");
}

@font-face {
    font-family: "CenturyGothicRegular";
    src: local("CenturyGothicRegular"),
        url(./fonts/CenturyGothicRegular.ttf) format("truetype");
}

@font-face {
    font-family: "Quicksand_Book";
    src: local("Quicksand_Book"),
        url(./fonts/Quicksand_Book.otf) format("truetype");
}

html {
    height: 100%
}

body {
    overflow: auto;
    background-color: #f8fafd!important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.App {
    padding: 0px !important;
    height: auto;
}
.loader {
    display: flex;
    flex-direction: column;
    /* background-color: rgba(0, 0, 0, 0.4); */
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 85%;
    height: 65%;
}

.user-profile-img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.user-profile-img-small {
    width: 40px;
    height: 40px;
    border-radius: 22.5px;
}
.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    border-radius: 7px;
    border: none;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 14px;
}
.react-datepicker__input-container input.gray {
    background-color: #f8f9fb;
}
.react-datepicker__input-container input.full-width {
  width: 100%;
}
.red {
    background-color: #f8fafd;
}
.css-3gw7uw-Control {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.input {
    border: 0px;
    outline-color: transparent;
    width: 100%;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 11px;
    padding: 15px;
    background-color: #f8fafd;
    border-radius: 7px;
    border: 1px solid #ced4da;
}

.button {
    background-color: #b0ccd4;
    border: 0;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.05);
    width: 48%;
    font-family: CenturyGothicBold;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    border-radius: 8px;
    width: 100%;
}

.input {
    border: 0px;
    outline-color: transparent;
    width: 100%;
    font-family: CenturyGothicRegular;
    color: #102935;
    font-size: 11px;
    padding: 15px;
    background-color: #f8fafd;
    border-radius: 7px;
    border: 1px solid #ced4da;
}

.button {
    background-color: #b0ccd4;
    border: 0;
    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.05);
    width: 48%;
    font-family: CenturyGothicBold;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    border-radius: 8px;
    width: 100%;
}
.css-b62m3t-container {
    width: 100% !important;
}
.col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rv-xy-plot {
    width: 100% !important;
}

.react-time-input-picker {
   width: 350px!important;
}

.react-time-input-picker input{
    width: 175px!important;
 }

 
.react-time-input-picker #react-time-input-picker__hourInput {
    width: 175px!important;
}


.react-time-input-picker #react-time-input-picker__minuteInput {
    width: 175px!important;
}

.react-calendar {
    border: 0 !important;
}

.react-calendar__tile {
    height: 70px;
}

.react-calendar__navigation__label__labelText--from {
    text-transform: capitalize;
    font-family: CenturyGothicBold;
}

.react-calendar__month-view__weekdays__weekday {
    font-size: 14px;
    color: #6b7389;
    font-family: CenturyGothicRegular;
    text-transform: capitalize;
    cursor: pointer !important;
    opacity: 0.7;
}

.react-calendar__month-view__weekdays__weekday abbr {
    cursor: default !important;
    text-decoration: none !important;
}

.react-calendar__tile--active {
    background: #f0b594 !important;
    color: #fff !important;
}

.react-calendar__tile--active  {
    color: #000 !important;
}


.react-calendar__tile--active:enabled,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    /* background: #e70220; */
    background: #f0b594 !important;

    color: #fff !important;
}
.react-calendar__tile
    .react-calendar__tile--now
    .react-calendar__tile--active
    .react-calendar__tile--range
    .react-calendar__tile--rangeStart
    .react-calendar__tile--rangeEnd
    .react-calendar__tile--rangeBothEnds
    .react-calendar__month-view__days__da {
    color: white !important;
}
.react-calendar__tile,
.react-calendar__month-view__days__day {
    font-size: 14px;
    width: 50px;
    height: 50px;
    border-radius: 100%;

    /* margin: 20px; */
}
.react-calendar__tile--now {
    background: transparent !important;
    border-radius: 50%;
    color: #102935 !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #102935 !important;
}

.react-calendar__month-view__days__day {
    color: #102935 !important;
    font-family: CenturyGothicBold;
}

.react-calendar__month-view__days__day--neighboringMonth {
    font-family: CenturyGothicRegular;
}

.react-calendar__tile:disabled {
    background-color: transparent!important;
    text-decoration: line-through;
    text-decoration-color: red;
    opacity: 0.5;
}

/* .react-calendar__tile--active {
    background: #f0b594 !important;
    border-radius: 50%;
    font-family: CenturyGothicBold;
    font-size: 11px;
    color: #fff !important;
}
.react-calendar__month-view__weekdays {
    font-family: CenturyGothicRegular;
    color: #6b7389;
    font-size: 11px;
}
.react-calendar__tile {
    font-family: CenturyGothicBold;
    font-size: 11px;
    color: #102935 !important;
}
.react-calendar__tile--now {
    background: #f0b594 !important;
    border-radius: 50%;
    color: #fff !important;
}
.react-calendar__navigation__label__labelText {
    font-family: CenturyGothicBold;
    text-transform: capitalize;
    color: #102935;
}

.react-calendar {
    margin-top: 20px;
} */
.list-col3 {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
    padding: 0;
    list-style-type: none;
    padding-top: 10px;
}
.list-col2 {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding: 0;
    list-style-type: none;
    padding-top: 10px;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.select-button {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
    font-family: CenturyGothicRegular !important;
    color: #102935 !important;
    font-size: 11px !important;
    width: 100%;
    text-align: left !important;
}

.select-button::after {
    float: right !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.modal-content {
    border-radius: 15px !important;
    /* overflow: hidden !important; */
}

.modal-content > div:nth-child(2) {
    padding: 25px;
}

.rdt_TableRow:nth-child(odd) {
    background-color: #f2f2f2;
}

.rdt_TableHead {
    font-weight: bold!important;
}

.swal-wide{
    width:850px !important;
}

.swal-wide .swal2-input {
    width: 80% !important;
}

.option-wrapper{
    display: inline-flex;
    background: #fff;
    height: 100px;
    width: 400px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 15px;
  }
  .option-wrapper .option{
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
  }


  .option-wrapper .option span{
    font-size: 20px;
    color: #808080;
  }

  .option-wrapper input[type="radio"]{
    display: none;
  }

  .option-wrapper input[type="radio"]:checked + label {
    border-color: #f0b594;
    background: #f0b594;
  }

  

  .option-wrapper input[type="radio"]:checked + label span {
        color: #fff;
    }

  /* #option-1:checked ~ .option-1,
  #option-2:checked ~ .option-2 {
    border-color: #f0b594;
    background: #f0b594;
  }
  #option-1:checked ~ .option-1 .dot,
  #option-2:checked ~ .option-2 .dot{
    background: #fff;
  }
  #option-1:checked ~ .option-1 .dot::before,
  #option-2:checked ~ .option-2 .dot::before{
    opacity: 1;
    transform: scale(1);
  }
  
  #option-1:checked ~ .option-1 span,
  #option-2:checked ~ .option-2 span{
    color: #fff;
  } */


  .loader-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    /* background: white
        url("https://media.giphy.com/media/8agqybiK5LW8qrG3vJ/giphy.gif") center
        no-repeat; */
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    margin: auto;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.stat-box {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    /* margin-bottom: 10px; */
    margin: 10px;
  }
  
  .stat-box span:first-child {
    font-size: 24px;
    font-weight: bold;
  }
  
  .stat-box span:last-child {
    font-size: 14px;
    color: #666;
  }